import {
  SET_EVENTS_PAGE_NUMBER,
  SET_PENDING,
  SET_EVENTS_PAGE_ITEM_COUNT,
  SET_DEVICE_LOGS_DATA,
  RESET_DEVICE_LOGS_DATA,
  SET_EVENTS_DATA,
  SET_CUSTOMER_EVENTS_DATA,
  SET_CUSTOMER_EVENTS_PAGE_NUMBER,
  SET_CUSTOMER_EVENTS_PAGE_ITEM_COUNT,
  SET_CUSTOMERS_DATA,
  SET_CUSTOMERS_PAGE_NUMBER,
  SET_CUSTOMERS_PAGE_ITEM_COUNT,
  SET_INSTALLS_DATA,
  SET_INSTALLS_FILTERS,
  SET_INSTALLS_PAGE_NUMBER,
  SET_INSTALLS_PAGE_ITEM_COUNT,
  SET_EVENT_DATA,
  SET_ALL_INSTALLS,
} from './types';

export const initialState = {
  events: {
    data: [],
    count: 0,
    pageNumber: 1,
    pageItemCount: 25,
  },
  event: {
    data: [],
    count: 0,
    params: null,
  },
  deviceLogs: {
    events: {
      data: [],
      count: 0,
      pageNumber: 1,
      pageItemCount: 25,
    },
    props: [],
    installs: [],
  },
  customers: {
    data: [],
    count: 0,
    pageNumber: 1,
    pageItemCount: 25,
  },
  installs: {
    data: [],
    chartData: {},
    fields: {},
    count: 0,
    pageNumber: 1,
    pageItemCount: 25,
    all: 0,
    today: 0,
    todayNonOrganic: 0,
    todayOrganic: 0,
  },
  pending: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENTS_DATA:
      return {
        ...state,
        events: {
          ...state.events,
          data: action.payload.data,
          count: action.payload.count,
        },
      };
    case SET_EVENT_DATA:
      return {
        ...state,
        event: {
          ...state.event,
          data: action.payload?.events,
          count: action.payload?.count,
          params: action.payload?.params,
        },
      };
    case SET_DEVICE_LOGS_DATA:
      return {
        ...state,
        deviceLogs: {
          ...state.deviceLogs,
          props: action.payload.props,
          installs: action.payload.installs,
        },
      };
    case RESET_DEVICE_LOGS_DATA:
      return {
        ...state,
        deviceLogs: initialState.deviceLogs,
      };
    case SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };
    case SET_EVENTS_PAGE_NUMBER:
      return {
        ...state,
        events: {
          ...state.events,
          pageNumber: action.payload,
        },
      };
    case SET_EVENTS_PAGE_ITEM_COUNT:
      return {
        ...state,
        events: {
          ...state.events,
          pageItemCount: action.payload,
        },
      };
    case SET_CUSTOMER_EVENTS_DATA:
      return {
        ...state,
        deviceLogs: {
          ...state.deviceLogs,
          events: {
            ...state.deviceLogs.events,
            data: action.payload.events,
            count: action.payload.count,
          },
        },
      };
    case SET_CUSTOMER_EVENTS_PAGE_NUMBER:
      return {
        ...state,
        deviceLogs: {
          ...state.deviceLogs,
          events: {
            ...state.deviceLogs.events,
            pageNumber: action.payload,
          },
        },
      };
    case SET_CUSTOMER_EVENTS_PAGE_ITEM_COUNT:
      return {
        ...state,
        deviceLogs: {
          ...state.deviceLogs,
          events: {
            ...state.deviceLogs.events,
            pageItemCount: action.payload,
          },
        },
      };

    case SET_CUSTOMERS_DATA:
      return {
        ...state,
        customers: {
          ...state.customers,
          data: action.payload.data,
          count: action.payload.count,
        },
      };
    case SET_CUSTOMERS_PAGE_NUMBER:
      return {
        ...state,
        customers: {
          ...state.customers,
          pageNumber: action.payload,
        },
      };
    case SET_CUSTOMERS_PAGE_ITEM_COUNT:
      return {
        ...state,
        customers: {
          ...state.customers,
          pageItemCount: action.payload,
        },
      };

    case SET_INSTALLS_FILTERS:
      return {
        ...state,
        installs: {
          ...state.installs,
          fields: action.payload,
        },
      };

    case SET_INSTALLS_DATA:
      return {
        ...state,
        installs: {
          ...state.installs,
          data: action.payload.data,
          chartData: action.payload.chartData,
          count: action.payload.count,
        },
      };
    case SET_INSTALLS_PAGE_NUMBER:
      return {
        ...state,
        installs: {
          ...state.installs,
          pageNumber: action.payload,
        },
      };
    case SET_INSTALLS_PAGE_ITEM_COUNT:
      return {
        ...state,
        installs: {
          ...state.installs,
          pageItemCount: action.payload,
        },
      };
    case SET_ALL_INSTALLS:
      return {
        ...state,
        installs: {
          ...state.installs,
          all: action.payload.all,
          today: action.payload.today,
          todayOrganic: action.payload.todayOrganic,
          todayNonOrganic: action.payload.todayNonOrganic,
        },
      };

    default:
      return state;
  }
};
