import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import {
  Home as HomeIcon,
  AttachMoney as AttachMoneyIcon,
  Event as EventIcon,
  // NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  Apps as AppsIcon,
  NotificationsActive as NotificationsIcon,
  BarChart as BarChartIcon,
} from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

// styles
import useStyles from './styles';
import { ROUTES, NAMES, IDS } from '../../constants/routes';

// components
import SidebarLink from './components/SidebarLink/SidebarLink';
import { setSidebarOpen } from '../../store/app/actions';

const structure = [
  { id: IDS.OVERVIEW, label: NAMES[IDS.OVERVIEW], link: ROUTES[IDS.OVERVIEW], icon: <HomeIcon /> },
  {
    id: IDS.PROJECT,
    label: NAMES[IDS.PROJECT],
    link: ROUTES[IDS.PROJECT],
    icon: <AppsIcon />,
    children: [{ label: NAMES[IDS.PRODUCTS], link: ROUTES[IDS.PRODUCTS] }],
  },
  {
    id: IDS.MARKETING,
    label: NAMES[IDS.MARKETING],
    link: ROUTES[IDS.MARKETING],
    icon: <BarChartIcon />,
    children: [
      { label: NAMES[IDS.MARKETING_ADS], link: ROUTES[IDS.MARKETING_ADS] },
      { label: NAMES[IDS.MARKETING_TIMELINE_REPORT], link: ROUTES[IDS.MARKETING_TIMELINE_REPORT] },
    ],
  },
  {
    id: IDS.SUBTRACK,
    label: NAMES[IDS.SUBTRACK],
    link: ROUTES[IDS.SUBTRACK],
    icon: <AttachMoneyIcon />,
    children: [
      { label: NAMES[IDS.REVENUE], link: ROUTES[IDS.REVENUE] },
      { label: NAMES[IDS.TRANSACTIONS], link: ROUTES[IDS.TRANSACTIONS] },
    ],
  },
  {
    id: IDS.EVTRACK,
    label: NAMES[IDS.EVTRACK],
    link: ROUTES[IDS.EVTRACK],
    icon: <EventIcon />,
    children: [
      { label: NAMES[IDS.INSTALLS], link: ROUTES[IDS.INSTALLS] },
      { label: NAMES[IDS.USERS], link: ROUTES[IDS.USERS] },
      { label: NAMES[IDS.EVENTS], link: ROUTES[IDS.EVENTS] },
      { label: NAMES[IDS.DEVICE_LOGS], link: ROUTES[IDS.DEVICE_LOGS] },
    ],
  },
  {
    id: IDS.MESSAGING,
    label: NAMES[IDS.MESSAGING],
    link: ROUTES[IDS.MESSAGING],
    icon: <NotificationsIcon />,
    children: [
      { label: NAMES[IDS.NOTIFICATION], link: ROUTES[IDS.NOTIFICATION] },
      { label: NAMES[IDS.NOTIFICATIONS], link: ROUTES[IDS.NOTIFICATIONS] },
    ],
  },
  { id: 5, type: 'divider' },
  { id: 6, type: 'title', label: 'HELP' },
  // { id: 7, label: NAMES[IDS.APP_CONTENT], link: ROUTES[IDS.APP_CONTENT], icon: <LibraryIcon /> },
  { id: 8, label: 'Support', link: 'https://metrix.work/forum', icon: <SupportIcon /> },
  { id: 9, label: 'FAQ', link: 'https://metrix.work/forum', icon: <FAQIcon /> },
  // { id: 15, type: 'divider' },
  // { id: 16, type: 'title', label: 'UI' },
  // {
  //   id: 17,
  //   label: 'Typography',
  //   link: '/root/typography',
  //   icon: <TypographyIcon />,
  // },
  // { id: 18, label: 'Tables', link: '/root/tables', icon: <TableIcon /> },
  // {
  //   id: 19,
  //   label: 'Notifications',
  //   link: '/root/notifications',
  //   icon: <NotificationsIcon />,
  // },
  // {
  //   id: 20,
  //   label: 'UI Elements',
  //   link: '/root/ui',
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: 'Icons', link: '/root/ui/icons' },
  //     { label: 'Charts', link: '/root/ui/charts' },
  //     { label: 'Maps', link: '/root/ui/maps' },
  //   ],
  // },
];

function Sidebar() {
  const classes = useStyles();
  const theme = useTheme();

  // global
  const dispatch = useDispatch();
  const isSidebarOpened = useSelector((state) => state.app.isSidebarOpened);

  // local
  const [isPermanent, setPermanent] = useState(true);

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  const handleTabbarToggle = () => {
    dispatch(setSidebarOpen(!isSidebarOpened));
  };

  const handleTabbarOpen = () => {
    dispatch(setSidebarOpen(true));
  };

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={handleTabbarToggle}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink key={link.id} link={link} isSidebarOpened={isSidebarOpened} onSideBarOpen={handleTabbarOpen} {...link} />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default Sidebar;
