import { createAction } from 'redux-actions';
import * as TYPES from './types';
import * as projectApi from '../../api/projects';

export const setPending = createAction(TYPES.SET_PENDING);
export const setProjects = createAction(TYPES.SET_PROJECTS);
export const setSelectedProjectId = createAction(TYPES.SET_SELECTED_PROJECT);
export const setEditProjectModalShow = createAction(TYPES.SET_EDIT_PROJECT_MODAL_SHOW);
export const setProducts = createAction(TYPES.SET_PRODUCTS);
export const setProductsPending = createAction(TYPES.SET_PRODUCTS_PENDING);

export const getProducts = () => async (dispatch) => {
  dispatch(setProductsPending(true));
  try {
    const products = await projectApi.getProducts();
    dispatch(setProducts(products));
    return products;
  } catch (error) {
    console.log('[GET PRODUCTS ERROR]', error);
    throw error;
  } finally {
    dispatch(setProductsPending(false));
  }
};

export const createProduct = (data) => async (dispatch) => {
  dispatch(setProductsPending(true));
  try {
    const product = await projectApi.createProduct(data);
    dispatch(getProducts());
    return product;
  } catch (error) {
    console.log('[CREATE PRODUCT ERROR]', error);
    throw error;
  } finally {
    dispatch(setProductsPending(false));
  }
};

export const updateProduct = (data) => async (dispatch) => {
  dispatch(setProductsPending(true));
  try {
    const product = await projectApi.editProduct(data);
    dispatch(getProducts());
    return product;
  } catch (error) {
    console.log('[UPDATE PRODUCT ERROR]', error);
    throw error;
  } finally {
    dispatch(setProductsPending(false));
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  dispatch(setProductsPending(true));
  try {
    await projectApi.deleteProduct(id);
    dispatch(getProducts());
  } catch (error) {
    console.log('[DELETE PRODUCT ERROR]', error);
    throw error;
  } finally {
    dispatch(setProductsPending(false));
  }
};

export const getProjects = () => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const projects = await projectApi.getProjects();
    dispatch(setProjects(projects));
    return projects;
  } catch (error) {
    console.log('[GET PROJECTS ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const getProject = (id) => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const project = await projectApi.getProject(id);
    return project;
  } catch (error) {
    console.log('[GET PROJECT ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const createProject = (data) => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const project = await projectApi.createProject(data);
    dispatch(setSelectedProjectId(project?._id));
    return project;
  } catch (error) {
    console.log('[CREATE PROJECT ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const updateProject = (data) => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const project = await projectApi.updateProject(data);
    // dispatch(setSelectedProjectId(project?._id));
    return project;
  } catch (error) {
    console.log('[UPDATE PROJECT ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};
