import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { Box, IconButton, Link } from '@material-ui/core';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';

// icons
import { mdiFacebook as FacebookIcon, mdiTwitter as TwitterIcon, mdiGithub as GithubIcon } from '@mdi/js';

// styles
import useStyles from './styles';

// components
import Header from '../Header';
import Sidebar from '../Sidebar';

// pages
import Dashboard from '../../pages/overview/Overview';
import Typography from '../../pages/typography';
import Notifications from '../../pages/notifications';
import Maps from '../../pages/maps';
import Tables from '../../pages/tables';
import Icons from '../../pages/icons';
import Charts from '../../pages/charts';
import ProjectDashboard from '../../pages/project';
import Products from '../../pages/project/products';
import Revenues from '../../pages/subtrack/revenues';
import Transactions from '../../pages/subtrack/transactions';
import Transaction from '../../pages/subtrack/transaction';
import SubtrackDashboard from '../../pages/subtrack/dashboard';
// import EvtrackDashboard from '../../pages/evtrack/dashboard';
import EvtrackEvents from '../../pages/evtrack/events';
import EvtrackEvent from '../../pages/evtrack/event';
import EvtrackDeviceLogs from '../../pages/evtrack/device-logs';
import EvtrackCustomers from '../../pages/evtrack/customers';
import EvtrackInstalls from '../../pages/evtrack/installs';
import EvtrackCustomer from '../../pages/evtrack/customer';
import EvtrackDashboard from '../../pages/evtrack/dashboard';
import Messaging from '../../pages/messaging';
import MessagingNotification from '../../pages/messaging/notification';
import MessagingNotifications from '../../pages/messaging/notifications';

import MarketingDashboard from '../../pages/marketing/dashboard';
import MarketingTimelineReport from '../../pages/marketing/timeline-report';
import MarketingAds from '../../pages/marketing/ads';
import MarketingAd from '../../pages/marketing/ads/ad';

// import AppInspiro from '../../pages/apps/inspiro';
import AppInspiroBackground from '../../pages/apps/inspiro/background';
import AppInspiroCategory from '../../pages/apps/inspiro/category';
import AppInspiroCollection from '../../pages/apps/inspiro/collection';
import AppInspiroMessage from '../../pages/apps/inspiro/message';
// import AppInspiroPreview from '../../pages/apps/inspiro/preview';
// import AppInspiroUpload from '../../pages/apps/inspiro/upload';

import UtilsDevices from '../../pages/utils/devices';

import { ROUTES, NAMES, IDS } from '../../constants/routes';

function Layout() {
  const classes = useStyles();
  // const navigate = useNavigate();

  // global
  const dispatch = useDispatch();
  const isSidebarOpened = useSelector((state) => state.app.isSidebarOpened);

  return (
    <div className={classes.root}>
      <Header />
      <Sidebar />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        <Routes>
          <Route exact path={ROUTES[IDS.OVERVIEW]} element={<Dashboard />} />
          {/* PROJECT */}
          <Route exact path={ROUTES[IDS.PROJECT]} element={<ProjectDashboard />} />
          <Route exact path={ROUTES[IDS.PRODUCTS]} element={<Products />} />

          {/* MARKETING */}
          <Route exact path={ROUTES[IDS.MARKETING]} element={<MarketingDashboard />} />
          <Route exact path={ROUTES[IDS.MARKETING_TIMELINE_REPORT]} element={<MarketingTimelineReport />} />
          <Route exact path={ROUTES[IDS.MARKETING_ADS]} element={<MarketingAds />} />
          <Route path={`${ROUTES[IDS.MARKETING_ADS]}/:id`} element={<MarketingAd />} />

          {/* SUBTRACK */}
          <Route exact path={ROUTES[IDS.SUBTRACK]} element={<SubtrackDashboard />} />
          <Route exact path={ROUTES[IDS.REVENUE]} element={<Revenues />} />
          <Route exact path={ROUTES[IDS.TRANSACTIONS]} element={<Transactions />} />
          <Route path={`${ROUTES[IDS.TRANSACTIONS]}/:id`} element={<Transaction />} />
          {/* EVTRACK */}
          <Route exact path={ROUTES[IDS.EVTRACK]} element={<EvtrackDashboard />} />
          <Route exact path={ROUTES[IDS.EVENTS]} element={<EvtrackEvents />} />
          <Route path={ROUTES[IDS.EVENT]} element={<EvtrackEvent />} />
          <Route path={ROUTES[IDS.DEVICE_LOGS]} element={<EvtrackDeviceLogs />} />
          <Route exact path={ROUTES[IDS.USERS]} element={<EvtrackCustomers />} />
          <Route path={ROUTES[IDS.USER]} element={<EvtrackCustomer />} />
          <Route path={ROUTES[IDS.INSTALLS]} element={<EvtrackInstalls />} />

          <Route exact path={ROUTES[IDS.MESSAGING]} element={<Messaging />} />
          <Route path={ROUTES[IDS.NOTIFICATION]} element={<MessagingNotification />} />
          <Route path={ROUTES[IDS.NOTIFICATIONS]} element={<MessagingNotifications />} />

          <Route path={ROUTES[IDS.APPS_INSPIRO_BACKGROUND]} element={<AppInspiroBackground />} />
          <Route path={ROUTES[IDS.APPS_INSPIRO_CATEGORY]} element={<AppInspiroCategory />} />
          <Route path={ROUTES[IDS.APPS_INSPIRO_COLLECTION]} element={<AppInspiroCollection />} />
          <Route path={ROUTES[IDS.APPS_INSPIRO_MESSAGE]} element={<AppInspiroMessage />} />

          {/* <Route path={ROUTES[IDS.APPS_INSPIRO_CONTENT_PREVIEW]} element={<AppInspiroPreview />} />
          <Route path={ROUTES[IDS.APPS_INSPIRO_CONTENT_UPLOAD]} element={<AppInspiroUpload />} /> */}

          <Route path={ROUTES[IDS.UTILS_DEVICES]} element={<UtilsDevices />} />

          {/* <Route path="/root/typography" element={Typography} />
          <Route path="/root/tables" element={Tables} />
          <Route path="/root/notifications" element={Notifications} /> */}
          {/* <Route exact path="/root/ui" render={() => <Navigate to="/root/ui/icons" />} /> */}
          {/* <Route path="/root/ui/maps" element={Maps} />
          <Route path="/root/ui/icons" element={Icons} />
          <Route path="/root/ui/charts" element={Charts} /> */}
        </Routes>
        {/* <Box mt={5} width="100%" display="flex" alignItems="center" justifyContent="space-between">
          <div>
            <Link color="primary" href="https://metrix.work/" target="_blank" className={classes.link}>
              Metrix
            </Link>
            <Link color="primary" href="https://metrix.work/about" target="_blank" className={classes.link}>
              About Us
            </Link>
            <Link color="primary" href="https://metrix.work/blog" target="_blank" className={classes.link}>
              Blog
            </Link>
          </div>
          <div>
            <Link href="https://www.facebook.com/metrix" target="_blank">
              <IconButton aria-label="facebook">
                <Icon path={FacebookIcon} size={1} color="#6E6E6E99" />
              </IconButton>
            </Link>
            <Link href="https://twitter.com/metrix" target="_blank">
              <IconButton aria-label="twitter">
                <Icon path={TwitterIcon} size={1} color="#6E6E6E99" />
              </IconButton>
            </Link>
            <Link href="https://github.com/metrix" target="_blank">
              <IconButton aria-label="github" style={{ marginRight: -12 }}>
                <Icon path={GithubIcon} size={1} color="#6E6E6E99" />
              </IconButton>
            </Link>
          </div>
        </Box> */}
      </div>
    </div>
  );
}

export default Layout;
