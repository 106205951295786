import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import { Add as AddIcon, Delete as RemoveIcon } from '@material-ui/icons';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import { sendMessage, setToken, removeToken } from '../../../store/messaging/actions';
// components
import Widget from '../../../components/Widget/Widget';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import Breadcrumbs from '../../../components/Breadcrumbs';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.MESSAGING],
    name: NAMES[IDS.MESSAGING],
  },
];

const Notification = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [token, setTokenValue] = useState('');
  const [checkedTokens, setCheckedTokens] = useState([]);
  const dispatch = useDispatch();
  const isPending = useSelector((state) => state.messaging.pending);
  const tokens = useSelector((state) => state.messaging.tokens);

  useEffect(() => {
    setCheckedTokens([]);
  }, [tokens]);

  const handleTitleChange = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  const handleBodyChange = (e) => {
    e.preventDefault();
    setBody(e.target.value);
  };

  const handleTokenChange = (e) => {
    e.preventDefault();
    setTokenValue(e.target.value);
  };

  const handleTokenAdd = async (e) => {
    e.preventDefault();

    const currentIndex = tokens.findIndex((t) => t.indexOf(token) >= 0);

    if (token.length && currentIndex < 0) {
      dispatch(setToken(token));
      setCheckedTokens([...checkedTokens, token]);
      setTokenValue('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title.length && body.length && checkedTokens.length) {
      dispatch(
        sendMessage({
          to: checkedTokens,
          message: {
            title,
            body,
          },
        }),
      );
    }
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={10}>
          <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.NOTIFICATION]} />
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <TextField
                    className={classNames(classes.field, classes.fieldTitle)}
                    name="title"
                    id="title"
                    label="title"
                    value={title}
                    error={false}
                    onChange={handleTitleChange}
                    variant="outlined"
                  />
                  <TextField
                    className={classNames(classes.field, classes.fieldBody)}
                    name="body"
                    id="body"
                    label="body"
                    value={body}
                    error={false}
                    onChange={handleBodyChange}
                    variant="outlined"
                  />

                  <Button variant="contained" size="large" color="primary" type="submit" className={classes.button}>
                    Sent Message
                  </Button>
                </form>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.filedWrapToken}>
                  <TextField
                    className={classNames(classes.field, classes.fieldToken)}
                    name="token"
                    id="token"
                    label="FCM token"
                    value={token}
                    error={false}
                    onChange={handleTokenChange}
                    variant="outlined"
                  />
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    onClick={handleTokenAdd}
                    className={classes.addButton}
                    disabled={!token.length}
                  >
                    <AddIcon />
                  </Fab>
                </div>

                <List className={classes.list}>
                  {tokens.map((t, index) => {
                    const labelId = `checkbox-list-label-${t}`;

                    const handleRemoveToken = (e) => {
                      e.preventDefault();
                      dispatch(removeToken(t));
                    };

                    const handleCheckToggle = (e) => {
                      e.preventDefault();
                      const currentIndex = checkedTokens.indexOf(t);
                      const newChecked = [...checkedTokens];

                      if (currentIndex === -1) {
                        newChecked.push(t);
                      } else {
                        newChecked.splice(currentIndex, 1);
                      }

                      setCheckedTokens(newChecked);
                    };

                    return (
                      <ListItem key={`${t}-${index}`} disablePadding dense className={classes.item}>
                        <ListItemButton role={undefined} onClick={handleCheckToggle} dense>
                          <ListItemIcon className={classes.itemIcon}>
                            <Checkbox
                              edge="start"
                              checked={checkedTokens.indexOf(t) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <div className={classes.itemTextWrap}>
                            <Typography noWrap className={classes.itemText}>
                              {t}
                            </Typography>
                          </div>
                        </ListItemButton>

                        <ListItemSecondaryAction className={classes.rightButton}>
                          <IconButton
                            // color="secondary"
                            edge="start"
                            aria-label="remove"
                            onClick={handleRemoveToken}
                            className={classes.removeButton}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

export default Notification;

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  main: {
    marginTop: theme.spacing(2),
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'row',
  },
  filtersLoaderWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(4),
  },
  filters: {},
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    width: '100%',
  },
  fieldBody: {
    marginTop: theme.spacing(2),
  },
  filedWrapToken: {
    position: 'relative',
  },
  button: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  addButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  tokens: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  list: {
    overflow: 'hidden',
  },
  item: {
    padding: 0,
  },
  itemIcon: {
    minWidth: '0 !important',
  },
  itemTextWrap: {
    maxWidth: '100%',
  },
  itemText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rightButton: {
    right: '0 !important',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,1) !important',
  },
  removeButton: {},
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
