import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import JSONPretty from 'react-json-pretty';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getTransaction } from '../../../store/subtrack/actions';
// components
import Widget from '../../../components/Widget';
import { Typography } from '../../../components/Wrappers';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import { ROLES } from '../../../constants/roles';
import Breadcrumbs from '../../../components/Breadcrumbs';
import withRouter from '../../../hooks/with-router';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.SUBTRACK],
    name: NAMES[IDS.SUBTRACK],
  },
  {
    route: ROUTES[IDS.TRANSACTIONS],
    name: NAMES[IDS.TRANSACTIONS],
  },
];

const Transaction = () => {
  const classes = useStyles();
  const { id } = useParams();

  // global
  const dispatch = useDispatch();
  const role = useSelector((state) => state.account.role);
  const data = useSelector((state) => state.subtrack.transaction);

  const {
    productId,
    platform,
    type,
    orderId,
    idfa,
    idfm,
    idfv,
    appsFlyerUID,
    firebaseClientId,
    price,
    currencyCode,
    countryCode,
    status,
    createdAt,
    updatedAt,
    activities,
  } = data;

  const isPending = useSelector((state) => state.subtrack.pending);

  useEffect(() => {
    if (id) {
      dispatch(getTransaction(id));
    }
  }, [id]);

  return (
    <>
      <Breadcrumbs links={breadcrumsRoutes} currentRoute={id} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget noBodyPadding disableWidgetMenu noHeaderPadding bodyClass={classes.tableOverflow}>
            <Grid container xs={12} className={classes.row}>
              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    Product ID
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  {productId && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {productId}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    Platform
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  {platform && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {platform}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    Type
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  {type && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {type}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} className={classes.row}>
              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    Order ID
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />
                  {orderId && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {orderId}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    IDFA
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    {idfa || ' - '}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    IDFM
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    {idfm || ' - '}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} className={classes.row}>
              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    IDFV
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  {idfv && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {idfv}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    AppsFlyer ID
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  {appsFlyerUID && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {appsFlyerUID}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    Firebase ID
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  {firebaseClientId && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {firebaseClientId}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} className={classes.row}>
              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    Price
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  {price && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {price}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    Currency Code
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  {currencyCode && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {currencyCode}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    Country Code
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  {countryCode && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {countryCode}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} className={[classes.row, classes.rowLast]}>
              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    Status
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  {status && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {status}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    Created At
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  {createdAt && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {moment(createdAt).format('YYYY-MM-DD h:mm:ss')}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container alignItems="center" xs={4}>
                <Grid container xs={3} className={classes.leftCol}>
                  <Divider orientation="vertical" flexItem />
                  <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                    Updated At
                  </Typography>
                </Grid>

                <Grid container xs={9} className={classes.col}>
                  <Divider orientation="vertical" flexItem />

                  {updatedAt && (
                    <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                      {moment(updatedAt).format('YYYY-MM-DD h:mm:ss')}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Divider orientation="horizontal" flexItem />
            {isPending && (
              <div className={classes.loaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            )}
          </Widget>
        </Grid>
      </Grid>

      {role === ROLES.OWNER && !_.isEmpty(data) && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget noBodyPadding disableWidgetMenu noHeaderPadding bodyClass={classes.tableOverflow}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography className={classes.heading}>Transaction</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <JSONPretty id="json-pretty" data={data} />
                  {/* <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography> */}
                </AccordionDetails>
              </Accordion>
            </Widget>
          </Grid>
        </Grid>
      )}

      {!_.isEmpty(activities) && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget noBodyPadding disableWidgetMenu noHeaderPadding bodyClass={classes.tableOverflow}>
              <TableContainer>
                <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Status
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Created At
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activities.map((row) => (
                      <TableRow hover tabIndex={-1} key={row.createdAt}>
                        <TableCell component="th" scope="row">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {row.status}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {moment(row.createdAt).format('YYYY-MM-DD h:mm:ss')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Widget>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withRouter(Transaction);

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  table: {
    minWidth: 750,
  },
  root: {
    width: '100%',
  },
  leftCol: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
  text: {
    padding: theme.spacing(1, 2),
  },
  row: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  rowLast: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  col: {
    // border: `1px solid ${theme.palette.divider}`,
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
