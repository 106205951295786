export const IDS = {
  OVERVIEW: 'OVERVIEW',
  PROJECT: 'PROJECT',
  PRODUCTS: 'PRODUCTS',
  SUBTRACK: 'SUBTRACK',
  SUBTRACK_DASHBOARD: 'SUBTRACK_DASHBOARD',
  TRANSACTIONS: 'TRANSACTIONS',
  REVENUE: 'REVENUE',
  TRANSACTION: 'TRANSACTION',
  EVTRACK: 'EVTRACK',
  USERS: 'USERS',
  USER: 'USER',
  INSTALLS: 'INSTALLS',
  EVENTS: 'EVENTS',
  EVENT: 'EVENT',
  EVTRACK_DASHBOARD: 'EVTRACK_DASHBOARD',
  DEVICE_LOGS: 'DEVICE_LOGS',
  MESSAGING: 'MESSAGING',
  NOTIFICATION: 'NOTIFICATION',
  NOTIFICATIONS: 'NOTIFICATIONS',
  MARKETING: 'MARKETING',
  MARKETING_DASHBOARD: 'MARKETING_DASHBOARD',
  MARKETING_TIMELINE_REPORT: 'MARKETING_TIMELINE_REPORT',
  MARKETING_ADS: 'MARKETING_ADS',
  MARKETING_AD: 'MARKETING_AD',
  APPS: 'APPS',
  APPS_INSPIRO: 'APPS_INSPIRO',
  APPS_INSPIRO_CATEGORY: 'APPS_INSPIRO_CATEGORY',
  APPS_INSPIRO_COLLECTION: 'APPS_INSPIRO_COLLECTION',
  APPS_INSPIRO_MESSAGE: 'APPS_INSPIRO_MESSAGE',
  APPS_INSPIRO_BACKGROUND: 'APPS_INSPIRO_BACKGROUND',
  // APPS_INSPIRO_CONTENT_PREVIEW: 'APPS_INSPIRO_CONTENT_PREVIEW',
  // APPS_INSPIRO_CONTENT_UPLOAD: 'APPS_INSPIRO_CONTENT_UPLOAD',
  UTILS: 'UTILS',
  UTILS_DEVICES: 'UTILS_DEVICES',
};

export const NAMES = {
  [IDS.OVERVIEW]: 'Overview',
  [IDS.PROJECT]: 'Project',
  [IDS.PRODUCTS]: 'Products',
  [IDS.SUBTRACK]: 'Subtrack',
  [IDS.SUBTRACK_DASHBOARD]: 'Dashboard',
  [IDS.TRANSACTIONS]: 'Transactions',
  [IDS.REVENUE]: 'Revenue',
  [IDS.TRANSACTION]: 'Transaction',
  [IDS.EVTRACK]: 'Evtrack',
  [IDS.USERS]: 'Users',
  [IDS.USER]: 'User',
  [IDS.INSTALLS]: 'Installs',
  [IDS.EVENTS]: 'Events',
  [IDS.EVENT]: 'Event',
  [IDS.EVTRACK_DASHBOARD]: 'Dashboard',
  [IDS.DEVICE_LOGS]: 'Device Logs',
  [IDS.MESSAGING]: 'Messaging',
  [IDS.NOTIFICATION]: 'Notification',
  [IDS.NOTIFICATIONS]: 'Notifications',
  [IDS.MARKETING]: 'Marketing',
  [IDS.MARKETING_DASHBOARD]: 'Dashboard',
  [IDS.MARKETING_TIMELINE_REPORT]: 'Timeline Report',
  [IDS.MARKETING_ADS]: 'Ads',
  [IDS.MARKETING_AD]: 'Ad',
  [IDS.APPS]: 'Apps',
  [IDS.APPS_INSPIRO]: 'Inspiro',
  [IDS.APPS_INSPIRO_BACKGROUND]: 'Background',
  [IDS.APPS_INSPIRO_CATEGORY]: 'Category',
  [IDS.APPS_INSPIRO_COLLECTION]: 'Collection',
  [IDS.APPS_INSPIRO_MESSAGE]: 'Message',
  // [IDS.APPS_INSPIRO_CONTENT_UPLOAD]: 'Inspiro Content Upload',
  [IDS.UTILS_DEVICES]: 'Utils Devices',
};

export const ROOT = '/root';

export const ROUTES = {
  [IDS.OVERVIEW]: '/overview',
  [IDS.PROJECT]: '/project',
  [IDS.PRODUCTS]: '/project/products',
  [IDS.SUBTRACK]: '/subtrack',
  [IDS.SUBTRACK_DASHBOARD]: '/subtrack/dashboard',
  [IDS.REVENUE]: '/subtrack/revenue',
  [IDS.TRANSACTIONS]: '/subtrack/transactions',
  [IDS.TRANSACTION]: '/subtrack/transactions/:id',
  [IDS.EVTRACK]: '/evtrack',
  [IDS.EVTRACK_DASHBOARD]: '/evtrack/dashboard',
  [IDS.USERS]: '/evtrack/users',
  [IDS.USER]: '/evtrack/users/:id',
  [IDS.INSTALLS]: '/evtrack/installs',
  [IDS.EVENTS]: '/evtrack/events',
  [IDS.EVENT]: '/evtrack/events/:id',
  [IDS.DEVICE_LOGS]: '/evtrack/device-logs',
  [IDS.MESSAGING]: '/messaging',
  [IDS.NOTIFICATION]: '/messaging/create',
  [IDS.NOTIFICATIONS]: '/messaging/notifications',
  [IDS.MARKETING]: '/marketing',
  [IDS.MARKETING_DASHBOARD]: '/marketing/dashboard',
  [IDS.MARKETING_TIMELINE_REPORT]: '/marketing/timeline-report',
  [IDS.MARKETING_ADS]: '/marketing/ads',
  [IDS.MARKETING_AD]: '/marketing/ads/:id',
  [IDS.UTILS_DEVICES]: '/utils/devices',
  [IDS.APPS]: '/apps',
  [IDS.APPS_INSPIRO]: '/inspiro',
  [IDS.APPS_INSPIRO_BACKGROUND]: '/apps/inspiro/background',
  [IDS.APPS_INSPIRO_CATEGORY]: '/apps/inspiro/category',
  [IDS.APPS_INSPIRO_COLLECTION]: '/apps/inspiro/collection',
  [IDS.APPS_INSPIRO_MESSAGE]: '/apps/inspiro/message',
  // [IDS.APPS_INSPIRO_CONTENT_PREVIEW]: '/apps/inspiro/content/preview',
  // [IDS.APPS_INSPIRO_CONTENT_UPLOAD]: '/apps/inspiro/content/upload',
};
