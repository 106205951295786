import * as TYPES from './types';
import { SET_SELECTED_PROJECT } from '../projects/types';

export const initialState = {
  pending: false,
  ads: {
    pending: false,
    data: [],
  },
  ad: {},
  adExpenses: {
    pending: false,
    data: [],
    count: 0,
    chartData: {
      current: {
        data: {},
        total: 0,
      },
    },
    chartDataPending: false,
    pageNumber: 1,
    pageItemCount: 25,
  },
  reports: {
    pending: false,
    data: [],
  },
  timelineReport: {
    pending: false,
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PROJECT:
      return {
        ...state,
        ...initialState,
        ads: {
          ...initialState.ads,
        },
        adExpenses: {
          ...initialState.adExpenses,
        },
      };

    case TYPES.SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case TYPES.SET_ADS_PENDING:
      return {
        ...state,
        ads: {
          ...state.ads,
          pending: action.payload,
        },
      };

    case TYPES.SET_ADS_DATA:
      return {
        ...state,
        ads: {
          ...state.ads,
          data: action.payload,
        },
      };

    case TYPES.SET_ADS_EXPENSES_PENDING:
      return {
        ...state,
        adExpenses: {
          ...state.adExpenses,
          pending: action.payload,
        },
      };

    case TYPES.SET_ADS_EXPENSES_DATA:
      return {
        ...state,
        adExpenses: {
          ...state.adExpenses,
          data: action.payload.data,
          count: action.payload.count,
        },
      };

    case TYPES.SET_ADS_EXPENSES_PAGE_NUMBER:
      return {
        ...state,
        adExpenses: {
          ...state.adExpenses,
          pageNumber: action.payload,
        },
      };

    case TYPES.SET_ADS_EXPENSES_PAGE_ITEM_COUNT:
      return {
        ...state,
        adExpenses: {
          ...state.adExpenses,
          pageItemCount: action.payload,
        },
      };

    case TYPES.SET_REPORTS_PENDING:
      return {
        ...state,
        reports: {
          ...state.reports,
          pending: action.payload,
        },
      };

    case TYPES.SET_REPORTS_DATA:
      return {
        ...state,
        reports: {
          ...state.reports,
          data: action.payload,
        },
      };

    case TYPES.SET_TIMELINE_REPORT_PENDING:
      return {
        ...state,
        timelineReport: {
          ...state.timelineReport,
          pending: action.payload,
        },
      };

    case TYPES.SET_TIMELINE_REPORT_DATA:
      return {
        ...state,
        timelineReport: {
          ...state.timelineReport,
          data: action.payload,
        },
      };

    default:
      return state;
  }
};
