import http from '../utils/http';

const PATH = '/apps';

export const getInspiroCategoryList = (query) => http.get(`${PATH}/inspiro/category/list${query ? `?${query}` : ''}`);

export const setInspiroCategoryListOrders = (data) =>
  http.post(`${PATH}/inspiro/category/list/orders`, {
    ...data,
  });

export const createInspiroCategory = (data) =>
  http.post(`${PATH}/inspiro/category`, {
    ...data,
  });

export const updateInspiroCategory = (data) =>
  http.put(`${PATH}/inspiro/category`, {
    ...data,
  });

export const deleteInspiroCategory = (id) => http.delete(`${PATH}/inspiro/category`, { data: { id } });

export const saveInspiroCategoryOrders = (data) =>
  http.post(`${PATH}/inspiro/category/orders`, {
    ...data,
  });

export const getInspiroCollectionList = (query) => http.get(`${PATH}/inspiro/collection/list${query ? `?${query}` : ''}`);

export const setInspiroCollectionListOrders = (data) =>
  http.post(`${PATH}/inspiro/collection/list/orders`, {
    ...data,
  });

export const createInspiroCollection = (data) =>
  http.post(`${PATH}/inspiro/collection`, {
    ...data,
  });

export const updateInspiroCollection = (data) =>
  http.put(`${PATH}/inspiro/collection`, {
    ...data,
  });

export const deleteInspiroCollection = (id) => http.delete(`${PATH}/inspiro/collection`, { data: { id } });

export const saveInspiroCollectionOrders = (data) =>
  http.post(`${PATH}/inspiro/collection/orders`, {
    ...data,
  });

export const getInspiroMessageList = (query) => http.get(`${PATH}/inspiro/message/list${query ? `?${query}` : ''}`);

export const setInspiroMessageListOrders = (data) =>
  http.post(`${PATH}/inspiro/message/list/orders`, {
    ...data,
  });

export const createInspiroMessage = (data) =>
  http.post(`${PATH}/inspiro/message`, {
    ...data,
  });

export const updateInspiroMessage = (data) =>
  http.put(`${PATH}/inspiro/message`, {
    ...data,
  });

export const deleteInspiroMessage = (id) => http.delete(`${PATH}/inspiro/message`, { data: { id } });

export const saveInspiroMessageOrders = (data) =>
  http.post(`${PATH}/inspiro/message/orders`, {
    ...data,
  });

export const getInspiroBackgroundList = (query) => http.get(`${PATH}/inspiro/background/list${query ? `?${query}` : ''}`);

export const setInspiroBackgroundListOrders = (data) =>
  http.post(`${PATH}/inspiro/background/list/orders`, {
    ...data,
  });

export const createInspiroBackground = (data) =>
  http.post(`${PATH}/inspiro/background`, {
    ...data,
  });

export const updateInspiroBackground = (data) =>
  http.put(`${PATH}/inspiro/background`, {
    ...data,
  });

export const deleteInspiroBackground = (id) => http.delete(`${PATH}/inspiro/background`, { data: { id } });

export const saveInspiroBackgroundOrders = (data) =>
  http.post(`${PATH}/inspiro/background/orders`, {
    ...data,
  });
