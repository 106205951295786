import { SET_USER_DATA } from './types';

export const initialState = {
  name: '',
  role: null,
  id: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        name: action.payload.name,
        role: action.payload.role,
        id: action.payload.id,
      };
    default:
      return state;
  }
};
