import * as TYPES from './types';

export const initialState = {
  pending: false,
  tokens: [],
  notifications: {
    data: [],
    count: 0,
    pending: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_NOTIFICATIONS_PENDING:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          pending: action.payload,
        },
      };

    case TYPES.SET_NOTIFICATIONS_DATA:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: action.payload.data,
          count: action.payload.count,
        },
      };

    case TYPES.SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case TYPES.SET_TOKENS:
      return {
        ...state,
        tokens: action.payload,
      };

    default:
      return state;
  }
};
