import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// pages
import Error from '../pages/error';
import Login from '../pages/login';
import Layout from './Layout';
import { ROUTES, IDS } from '../constants/routes';

// context
const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.token);

  const Private = ({ component: Component, ...rest }) => (isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />);
  const Public = ({ component: Component, ...rest }) => (isAuthenticated ? <Navigate to="/" /> : <Component {...rest} />);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={ROUTES[IDS.OVERVIEW]} />} />
        <Route exec path="/login" element={<Public component={Login} />} />
        <Route path="/*" element={<Private component={Layout} />} />
        <Route errorElement={<Error />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
