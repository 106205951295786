import * as TYPES from './types';
import { SET_SELECTED_PROJECT } from '../projects/types';

export const initialState = {
  pending: false,
  transactions: {
    data: [],
    chartData: {},
    count: 0,
    filters: {},
    filtersPending: false,
    pageNumber: 1,
    pageItemCount: 25,
    activeCount: 0,
    allCount: 0,
    todayCount: 0,
    todayOrganic: 0,
    todayNonOrganic: 0,
  },
  transaction: {},
  revenues: {
    data: [],
    count: 0,
    pending: false,
    chartData: {
      current: {
        data: {},
        total: 0,
      },
      prev: {
        data: {},
        total: 0,
      },
    },
    chartDataPending: false,
    filters: {},
    filtersPending: false,
    pageNumber: 1,
    pageItemCount: 25,
    allCount: 0,
    todayCount: 0,
    autoRenewal: 0,
    todayOrganic: 0,
    todayNonOrganic: 0,
  },
  charts: {
    data: [],
    pending: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PROJECT:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          data: initialState.transactions.data,
          count: initialState.transactions.count,
          chartData: initialState.transactions.chartData,
          pageNumber: initialState.transactions.pageNumber,
          pageItemCount: initialState.transactions.pageItemCount,
          activeCount: initialState.transactions.activeCount,
          filters: initialState.transactions.filters,
          filtersPending: true,
        },
      };
    case TYPES.SET_REVENUES_DATA:
      return {
        ...state,
        revenues: {
          ...state.revenues,
          data: action.payload.data,
          count: action.payload.count,
        },
      };
    case TYPES.SET_ALL_REVENUES_DATA:
      return {
        ...state,
        revenues: {
          ...state.revenues,
          allCount: action.payload.all,
          todayCount: action.payload.today,
          autoRenewal: action.payload.autoRenewal,
          todayOrganic: action.payload.todayOrganic,
          todayNonOrganic: action.payload.todayNonOrganic,
        },
      };

    case TYPES.SET_REVENUES_PENDING:
      return {
        ...state,
        revenues: {
          ...state.revenues,
          pending: action.payload,
        },
      };

    case TYPES.SET_REVENUES_FILTERS:
      return {
        ...state,
        revenues: {
          ...state.revenues,
          filters: action.payload,
        },
      };

    case TYPES.SET_REVENUES_FILTERS_PENDING:
      return {
        ...state,
        revenues: {
          ...state.revenues,
          filtersPending: action.payload,
        },
      };

    case TYPES.SET_REVENUES_CHART_DATA:
      return {
        ...state,
        revenues: {
          ...state.revenues,
          chartData: action.payload,
        },
      };

    case TYPES.SET_REVENUES_CHART_DATA_PENDING:
      return {
        ...state,
        revenues: {
          ...state.revenues,
          chartDataPending: action.payload,
        },
      };

    case TYPES.SET_REVENUES_PAGE_NUMBER:
      return {
        ...state,
        revenues: {
          ...state.revenues,
          pageNumber: action.payload,
        },
      };

    case TYPES.SET_REVENUES_PAGE_ITEM_COUNT:
      return {
        ...state,
        revenues: {
          ...state.revenues,
          pageItemCount: action.payload,
        },
      };

    case TYPES.SET_TRANSACTIONS_DATA:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          data: action.payload.data,
          count: action.payload.count,
          chartData: action.payload.chartData,
        },
      };

    case TYPES.SET_TRANSACTIONS_FILTERS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          filters: action.payload,
        },
      };

    case TYPES.SET_TRANSACTIONS_FILTERS_PENDING:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          filtersPending: action.payload,
        },
      };

    case TYPES.SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case TYPES.SET_TRANSACTIONS_PAGE_NUMBER:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          pageNumber: action.payload,
        },
      };

    case TYPES.SET_TRANSACTIONS_PAGE_ITEM_COUNT:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          pageItemCount: action.payload,
        },
      };

    case TYPES.SET_ACTIVE_TRANSACTIONS_DATA:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          activeCount: action.payload.length,
        },
      };

    case TYPES.SET_ALL_TRANSACTIONS_DATA:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          activeCount: action.payload.active,
          allCount: action.payload.all,
          todayCount: action.payload.today,
          todayOrganic: action.payload.todayOrganic,
          todayNonOrganic: action.payload.todayNonOrganic,
        },
      };

    case TYPES.SET_TRANSACTION_DATA:
      return {
        ...state,
        transaction: action.payload,
      };

    case TYPES.SET_CHARTS_DATA:
      return {
        ...state,
        charts: {
          ...state.charts,
          data: action.payload,
        },
      };

    case TYPES.SET_CHARTS_PENDING:
      return {
        ...state,
        charts: {
          ...state.charts,
          pending: action.payload,
        },
      };

    default:
      return state;
  }
};
