import * as TYPES from './types';

export const initialState = {
  inspiro: {
    pending: false,
    background: {
      data: [],
      count: 0,
    },
    category: {
      data: [],
      count: 0,
    },
    collection: {
      data: [],
      category_list: [],
      count: 0,
    },
    message: {
      data: [],
      count: 0,
      searchQuery: '',
      pageNumber: 1,
      pageItemCount: 25,
      activeCollectionId: '',
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_INSPIRO_PENDING:
      return {
        ...state,
        inspiro: {
          ...state.inspiro,
          pending: action.payload,
        },
      };

    case TYPES.SET_INSPIRO_ACTIVE_COLLECTION_ID:
      return {
        ...state,
        inspiro: {
          ...state.inspiro,
          message: {
            ...state.inspiro.message,
            activeCollectionId: action.payload,
          },
        },
      };

    case TYPES.SET_INSPIRO_BACKGROUND_LIST_DATA:
      return {
        ...state,
        inspiro: {
          ...state.inspiro,
          background: {
            ...state.inspiro.background,
            ...action.payload,
          },
        },
      };

    case TYPES.SET_INSPIRO_CATEGORY_LIST_DATA:
      return {
        ...state,
        inspiro: {
          ...state.inspiro,
          category: {
            ...state.inspiro.category,
            ...action.payload,
          },
        },
      };

    case TYPES.SET_INSPIRO_COLLECTION_LIST_DATA:
      return {
        ...state,
        inspiro: {
          ...state.inspiro,
          collection: {
            ...state.inspiro.collection,
            ...action.payload,
          },
        },
      };

    case TYPES.SET_INSPIRO_MESSAGE_LIST_DATA:
      return {
        ...state,
        inspiro: {
          ...state.inspiro,
          message: {
            ...state.inspiro.message,
            ...action.payload,
          },
        },
      };

    case TYPES.SET_INSPIRO_SEARCH_QUERY:
      return {
        ...state,
        inspiro: {
          ...state.inspiro,
          message: {
            ...state.inspiro.message,
            searchQuery: action.payload,
          },
          pending: true,
        },
      };

    case TYPES.SET_INSPIRO_PAGE_NUMBER:
      return {
        ...state,
        inspiro: {
          ...state.inspiro,
          message: {
            ...state.inspiro.message,
            pageNumber: action.payload,
          },
        },
      };

    case TYPES.SET_INSPIRO_PAGE_ITEM_COUNT:
      return {
        ...state,
        inspiro: {
          ...state.inspiro,
          message: {
            ...state.inspiro.message,
            pageItemCount: action.payload,
          },
        },
      };

    default:
      return state;
  }
};

reducer.blacklist = [];
reducer.whitelist = [];

export default reducer;
